*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;

}

/* You can add global styles to this file, and also import other style files */
html,
body {
  scrollbar-width: none;
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
